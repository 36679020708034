/**
 * @fileoverview This module provides the definition and creation of the Overview menu
 * for the application's sidebar navigation. The menu contains links to core areas
 * of the application such as Home, Transactions, Approvals, and Analytics.
 *
 * @author Sushmitha, Bakshi
 * @created 25 Aug 2023
 * @lastModified 30 Aug 2023
 */
import * as Icons from "@spenmo/splice";

import { useAppSelector } from "hook";

import { RootState } from "Redux/ConfigureStore";
import * as ROUTES from "constants/Routes.constants";

import { ISidebarMenuConfig } from "Views/Navigation/@types";
import { NAVIGATION_EVENTS } from "Views/Navigation/Constants";
import { useNavigationPermission } from "Views/Navigation/Hooks/useCheckPermission";

/**
 * `useOverviewMenu` function generates a list of menu items for the overview section of the sidebar.
 * Each menu item has an `id` for identification, a `props` object containing its display properties
 * (like label, icon, badge details) and its respective route defined in the `history` property.
 *
 * Only items with the `enabled` property set to true will be included in the final list.
 *
 * @returns {ISidebarMenuConfig[]} - An array of sidebar menu items for the overview section.
 */
export const useOverviewMenu = (): ISidebarMenuConfig[] => {
  const { totalPendingRequest } =
    useAppSelector((state: RootState) => state.approvalRequest) || 0;
  const { isAllowedCentralisedApproval, isAllowedAnalytics } =
    useNavigationPermission();

  const isProduction = window.__ENV__.API_BASE_URL.includes("api.spenmo.com");

  return [
    {
      id: "OVERVIEW_MENU_01",
      enabled: true,
      permission: true,
      props: {
        menu: {
          label: "Home",
          prefixIcon: Icons.HomeOutline,
          prefixActiveIcon: Icons.HomeFilled,
        },
        history: ROUTES.DASHBOARD_PATH,
        analytics: {
          enabled: true,
          data: { sidebar_event_source: NAVIGATION_EVENTS.HOME },
        },
      },
    } as ISidebarMenuConfig,
    {
      id: "OVERVIEW_MENU_02",
      enabled: true,
      permission: true,
      props: {
        menu: {
          label: "Transactions",
          prefixIcon: Icons.TransactionOutline,
          prefixActiveIcon: Icons.TransactionFilled,
        },
        history: ROUTES.TRANSACTIONS_PATH,
        analytics: {
          enabled: true,
          data: { sidebar_event_source: NAVIGATION_EVENTS.TRANSACTIONS },
        },
      },
    } as ISidebarMenuConfig,
    {
      id: "OVERVIEW_MENU_03",
      enabled: true,
      permission: isAllowedCentralisedApproval,
      props: {
        menu: {
          label: "Approvals",
          prefixIcon: Icons.ApprovalOutline,
          prefixActiveIcon: Icons.ApprovalFilled,
          badgeType: "notification",
          badgeLabel: totalPendingRequest,
        },
        history: ROUTES.APPROVAL_REQUEST_CENTER,
        analytics: {
          enabled: true,
          data: { sidebar_event_source: NAVIGATION_EVENTS.APPROVALS },
        },
      },
    } as ISidebarMenuConfig,
    {
      id: "OVERVIEW_MENU_04",
      enabled: true,
      permission: isAllowedAnalytics,
      props: {
        menu: {
          label: "Analytics",
          prefixIcon: Icons.ChartLineOutline,
          prefixActiveIcon: Icons.ChartLineFilled,
        },
        history: ROUTES.ANALYTICS_PATH,
        analytics: {
          enabled: true,
          data: { sidebar_event_source: NAVIGATION_EVENTS.ANALYTICS },
        },
      },
    } as ISidebarMenuConfig,
    {
      id: "OVERVIEW_MENU_05",
      enabled: !isProduction,
      permission: true,
      props: {
        menu: {
          label: "Reports",
          prefixIcon: Icons.ChartLineOutline,
          prefixActiveIcon: Icons.ChartLineFilled,
        },
        history: ROUTES.REPORTS_PATH,
      },
    } as ISidebarMenuConfig,
  ].filter((item) => item.enabled && item.permission);
};
